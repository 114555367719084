// history.js - Optimizado para history v4.10.1 y React Router v4
import { createBrowserHistory } from "history";

// Crear una única instancia de browserHistory para toda la aplicación
export const browserHistory = createBrowserHistory();

// Mantener la API existente para compatibilidad con el código existente
let current = null;
let stack = [];

const History = {
  init() {
    // Inicializar el listener de historia si es necesario
    browserHistory.listen((location) => {
      // Opcionalmente puedes hacer tracking de cambios de ruta aquí
    });
  },

  updatePathname(path) {
    if (current === path) return;

    if (this.lastPath() === path) {
      stack.shift();
      current = path;
    } else {
      if (current) stack.unshift(current);
      current = path;
    }
  },

  lastPath() {
    return stack[0];
  },

  // API para navegación compatible con React Router v4
  push(path) {
    browserHistory.push(path);
  },

  replace(path) {
    browserHistory.replace(path);
  },

  goBack() {
    browserHistory.goBack();
  },

  goForward() {
    browserHistory.goForward();
  },

  // Métodos adicionales que pueden ser útiles

  // Obtener la ubicación actual
  getLocation() {
    return browserHistory.location;
  },

  // Obtener el action actual (PUSH, REPLACE, POP)
  getAction() {
    return browserHistory.action;
  },

  // Bloquear navegación (útil para confirmar antes de salir de un formulario)
  block(message) {
    return browserHistory.block(message);
  },
};

export default History;
