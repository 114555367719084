import cn from "classnames";
import store from "./store";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HSplitFirst } from "./components/hsplits";
import {
  showLiveStream,
  showLiveStreamTeaser,
  showLinkToLiveRecording,
} from "./toggleLiveStreams";
import marked from "marked";
import _ from "lodash";
import removeHash from "./services/remove-hash";
import Auth from "./services/auth";
import { hasAuth, getOrastreamToken, getHomeData } from "./services/api";
import { resetMetadata } from "./actions/metas";
import { parseOnlyLetters, parseOnlyNumbers } from "./util/strings";
import { loadHomeData } from "./actions/entries";
import postit01 from "../images/filing-cabinet/postit_01.webp";
import postit02 from "../images/filing-cabinet/postit_02.webp";
import postit03 from "../images/filing-cabinet/postit_03.webp";
import postit04 from "../images/filing-cabinet/postit_04.webp";
import postit05 from "../images/filing-cabinet/postit_05.webp";
import cabinetDoorBottom from "../images/filing-cabinet/Open.webp";
import cabinetLabel from "../images/filing-cabinet/Cabinet_Technology.webp";
import signUp from "../images/filing-cabinet/NYA_Button_SuscribeHere.webp";
const postits = [postit01, postit02, postit03, postit04, postit05];

const showWelcome = false;
const showPostIt = showLiveStream || showLiveStreamTeaser;
const showNewsUpdate = false;
const ignore = (e) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  return false;
};

function externalizeHrefs(el) {
  let links = el.querySelectorAll("a");
  for (let i = 0; i < links.length; i++) {
    let a = links[i];
    if (location.hostname === a.hostname || !a.hostname.length) continue;
    a.setAttribute("target", "_blank");
  }
}

class PostIt extends Component {
  componentDidMount() {
    if (this.refs.link) externalizeHrefs(this.refs.link);
  }
  render() {
    let { id } = this.props;

    let state = store.getState();
    if (!state.postIt) return null;

    let it = state.postIt[id];
    if (!it) return null;

    let { url, displayWeb: display, content, style, videoId } = it;
    if (!display) return null;

    // No render if there is a top type "paper" post and it is displayed
    if (
      state.postIt["top"].style.indexOf("paper") !== -1 &&
      state.postIt["top"].display &&
      it.id !== "top"
    )
      return null;

    // Postit or paper style
    const type = style.slice(0, -2);
    const typeValue = style.slice(-1);
    let background = `url('${postit01}')`;
    if (type === "paper") {
      background = null;
    } else if (type === "postit") {
      background = `url('${postits[parseInt(style.slice(-1)) - 1]}')`;
    }
    if (!content) {
      return null;
    }

    content = marked(content);
    let cx = cn("post-it", id, type, typeValue);
    let contentCn = cn("content", type);

    const link = videoId ? `/video?vimeoId=${videoId}` : url;

    if (link) {
      const path = removeHash(link);
      if (path.indexOf("http") === 0) {
        return (
          <div
            ref="link"
            style={{ backgroundImage: background }}
            className={cx}
          >
            <a
              className={contentCn}
              href={path}
              dangerouslySetInnerHTML={{ __html: content }}
            ></a>
          </div>
        );
      }
      return (
        <div ref="link" style={{ backgroundImage: background }} className={cx}>
          <Link
            className={contentCn}
            to={path}
            dangerouslySetInnerHTML={{ __html: content }}
          ></Link>
        </div>
      );
    }

    return (
      <div style={{ backgroundImage: background }} className={cx}>
        <div
          className={contentCn}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    );
  }
}

function tryParseIcon(suffix, data, out, prev, columnRow, columnCount) {
  const imageKey = "icon" + suffix;
  const linkKey = "link" + suffix;
  const videoKey = "video" + suffix;
  const prevIconKey = "iconSize" + prev;
  const prevImageKey = "icon" + prev;
  const iconSizeKey = "iconSize" + suffix;
  const performanceLogKey = "performanceLogLink" + suffix;
  const hide = "hide" + suffix;
  const image = data[imageKey];
  const prevIconImage = data[prevImageKey];
  const videoId = data[videoKey];
  const iconSize = data[iconSizeKey];
  const prevIconSize = data[prevIconKey];
  const hideIcon = data[hide];
  let columnClass = "";
  let gridRow = { gridRow: `${suffix}` };

  if (hideIcon) {
    columnCount--;
    return;
  }

  if (!image) return;
  if (!_.has(image, "fields.file.url")) return;
  const url = image.fields.file.url;
  const backgroundImage = `url(${url})`;
  const link = videoId
    ? `/video?vimeoId=${videoId}`
    : data[performanceLogKey] || data[linkKey] || "";

  columnRow.push(`'${suffix}'`);

  if (iconSize !== "default" && iconSize !== undefined) {
    columnClass = iconSize;
    if (prevIconSize && prevIconSize == iconSize && prevIconImage) {
      columnClass = "small side-to-side";
      gridRow = { gridRow: `${prev}` };
      columnRow.pop();
      columnCount--;
    }
  }

  out.push({ link, backgroundImage, columnClass, gridRow });
}

function getSideIconsInfo(iconSide) {
  const data =
    iconSide == "left"
      ? store.getState().leftSideIcons.icons
      : store.getState().rightSideIcons.icons;
  let sideIconStyle = {};
  let columnCount = 0;

  if (!data) return [];
  const result = [];
  let columnRow = [];
  const iconkeys = Object.keys(data);
  let prevIconIndex;

  for (let i = 0; i <= iconkeys.length; i++) {
    let icon = iconkeys[i];

    if (i >= 1) prevIconIndex = parseOnlyNumbers(iconkeys[i - 1]);
    if (icon && parseOnlyLetters(icon) == "icon") {
      let iconArea = parseOnlyNumbers(icon);

      columnCount++;

      tryParseIcon(
        iconArea,
        data,
        result,
        prevIconIndex,
        columnRow,
        columnCount
      );
    }
  }
  columnCount = columnRow.length;
  columnRow = `"${columnRow.toString()}"`.replace(/[,"]/g, "");

  sideIconStyle[iconSide] = "30px";
  sideIconStyle.gridTemplateAreas = columnRow;

  return {
    columnCount,
    sideIconStyle,
    icons: result,
  };
}

class SideIcons extends Component {
  constructor(props, context) {
    super(props, context);
    const { icons, sideIconStyle, columnCount } = getSideIconsInfo(
      props.iconSide
    );
    this.state = {
      icons,
      sideIconStyle,
      columnCount,
      iconSide: props.iconSide,
    };
  }
  componentDidMount() {
    if (this.refs.icons) externalizeHrefs(this.refs.icons);
  }

  render() {
    let { icons, columnCount, iconSide, sideIconStyle } = this.state;
    icons.map(({ link, backgroundImage, columnClass, gridRow }, idx) => {
      console.log(removeHash(link));
    });
    let columnSizeStyle =
      columnCount == 5
        ? "extendedColumn"
        : columnCount == 6
        ? "extraColumn"
        : "defaultColumn";
    let iconSideStyle = iconSide === "left" ? "left-icons" : "right-icons";

    return (
      <div
        className={`side-icons ${iconSideStyle} ${columnSizeStyle}`}
        ref="icons"
        style={sideIconStyle}
      >
        {icons.map(({ link, backgroundImage, columnClass, gridRow }, idx) => {
          const path = removeHash(link);
          const token = getOrastreamToken();
          const orastreamLink =
            process.env.ORASTREAMLINK + `?user_jwt=${token}`;

          if (path.indexOf("http") === 0 || path.indexOf("https") === 0) {
            if (
              path.includes("store.neilyoungarchives") ||
              path.includes("nya.orastream")
            ) {
              return (
                <a
                  href={orastreamLink}
                  key={idx}
                  className={columnClass}
                  style={gridRow}
                >
                  <div className="icon" style={{ backgroundImage }} />
                </a>
              );
            } else {
              return (
                <a
                  href={path}
                  key={idx}
                  className={columnClass}
                  style={gridRow}
                >
                  <div className="icon" style={{ backgroundImage }} />
                </a>
              );
            }
          }

          return (
            <Link to={path} key={idx} className={columnClass} style={gridRow}>
              <div className="icon" style={{ backgroundImage }} />
            </Link>
          );
        })}
      </div>
    );
  }
}

export default class DrawerFront extends Component {
  constructor(props) {
    super(props);
    this.hideAuth0 = this.hideAuth0.bind(this);
    this.state = {
      dataLoaded: false,
    };
    console.log("%cstore", "color:red;font-size:1.1em;", store);
  }
  componentDidMount() {
    this.hideAuth0();
    getHomeData().then((homeData) => {
      loadHomeData(homeData);
      this.setState({
        dataLoaded: true,
      });
    });
    resetMetadata();
  }
  UNSAFE_componentWillUpdate() {
    this.hideAuth0();
  }
  hideAuth0() {
    if (!hasAuth()) {
      Auth.hide();
    }
  }
  postIt(id) {
    if (this.props.behindLogin || !this.state.dataLoaded) return null;
    return <PostIt id={id} />;
  }
  sideIcons(iconSide) {
    if (this.props.behindLogin || !this.state.dataLoaded) return null;
    return <SideIcons iconSide={iconSide} />;
  }
  render() {
    let { hideLinks } = this.props;
    let showLinks = !hideLinks;
    let releaseVersion = process.env.RELEASE_VERSION;
    const { showRightSideIcons } = store.getState().commonValues;

    let style = { width: "100%", height: "100%" };
    let barStyle = { width: "100%", height: "100%" };

    let fbLiveVideo =
      "https://www.facebook.com/NeilYoung/videos/10159601154410317/";
    let fbRecordedVideo =
      "https://www.facebook.com/NeilYoung/videos/10159601154410317/";

    let c = String.fromCharCode(169); //copyright symbol
    const orastreamLink =
      process.env.ORASTREAMLINK || "https://store.neilyoungarchives.com";
    return (
      <HSplitFirst height="109px" style={style}>
        <div style={barStyle}></div>
        <div id="drawer-front-container">
          <div id="drawer-front">
            <div className="front-wrapper">
              <Link to="/account?screen=plans" className="subscribe-btn">
                <img src={signUp} loading="lazy" />
              </Link>
              <Link to="/drawer">
                <img className="front" src={cabinetDoorBottom} loading="lazy" />
              </Link>
              <div className="ul panel" />
              <div className="ur panel" />
              <div className="ll panel" />
              <div className="lm panel" />
              <div className="lr panel" />
              {showNewsUpdate && (
                <Link to="/news">
                  <div className="nya-news-update" />
                </Link>
              )}
              {showWelcome && (
                <Link to="/video?id=qIuQOVBqRq6S2iMAg68oi">
                  <div className="welcome" />
                </Link>
              )}
              {showPostIt && (
                <a href={fbLiveVideo} target="_blank">
                  <div className="livestream" />
                </a>
              )}
              {showLinkToLiveRecording && (
                <a href={fbRecordedVideo} target="_blank">
                  <div className="livestream-recording" />
                </a>
              )}
              {this.sideIcons("left")}
              {showRightSideIcons && this.sideIcons("right")}
              {!showRightSideIcons && (
                <>
                  {this.postIt("top")}
                  {this.postIt("middle")}
                  {this.postIt("bottom")}
                </>
              )}
            </div>
          </div>
          <div className="drawer-top-line" />
        </div>
        <div className="drawer-front-bottom-items">
          <div className="items-top">
            THE CUTTING EDGE OF FILING CABINET TECHNOLOGY
          </div>

          {showLinks && (
            <div>
              <div className="items-right">
                <span className="c">{c}</span>2017 Shakey Pictures/
                <span className="c">{c}</span>2017 Warner Bros. Records
                <span className="pipe">|</span>
                <a target="_blank" onContextMenu={ignore} href={orastreamLink}>
                  powered by ORASTREAM
                </a>
                <span className="pipe">|</span>
                <a target="_blank" onContextMenu={ignore} href="/terms.html">
                  TERMS AND CONDITIONS
                </a>
                <span className="pipe">|</span>
                <a target="_blank" onContextMenu={ignore} href="/privacy.html">
                  PRIVACY POLICY
                </a>
                <span className="pipe">|</span>
                <a
                  target="_blank"
                  onContextMenu={ignore}
                  href="/privacy.html#adchoices"
                >
                  AD CHOICES
                </a>
                <span className="pipe">|</span>
                <Link to="/credits">CREDITS</Link>
                <span className="pipe">|</span>
                <Link to="/faq">FAQ</Link>
                <span className="pipe">|</span>
                <p>V. {releaseVersion}</p>
              </div>
            </div>
          )}
        </div>
      </HSplitFirst>
    );
  }
}
