import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import DrawerScrubber from "./components/drawer-scrubber";
import VerticalCenter from "./components/vertical-center";
import YearsDisplay from "./components/years-display";
import Items from "./components/drawer-items-perf";
import GlobalMenuIcon from "./components/global-menu-icon";
import GlobalMapIcon from "./components/global-map-icon";
import { inverseScale } from "./scale";
import { connect } from "react-redux";
import Easing from "./util/easing";
import compareReleaseDate from "./util/compare-release-date";
import {
  startSFXForScroll,
  stopSFXForScroll,
  setSpeed,
  setMaxSpeed,
} from "./drawer-sfx";
import { monthNumber } from "./util/months";
import { processState } from "./util/stateProcessing";
import { setCurrentTrack } from "./currentDate";
import { resetMetadata } from "./actions/metas";
import SplashScreen from "./splash-screen";

const onDelta = (delta) => {
  /* console.log(delta) */
  setSpeed(delta);
};

/* must match drawer scss */
const topBarHeight = 106;
const itemHeight = 60;

function measureWidth() {
  return inverseScale() * window.innerWidth;
}
//content area height of the drawer
function measureHeight() {
  return inverseScale() * window.innerHeight - topBarHeight;
}
function measureFullHeight() {
  return inverseScale() * window.innerHeight;
}

class TopBar extends React.Component {
  render() {
    return <div className="top-bar"></div>;
  }
}

class Drawer extends React.Component {
  setScroll(amt) {
    this.refs.items.setScroll(amt);
  }
  render() {
    let items = this.props.items.filter((item) => !item.isPlaylist);
    return (
      <div className="box">
        <Items ref="items" {...this.props} items={items} />
      </div>
    );
  }
}

let pos = 100;
let pixelPos = 0;
let prevPixelPos = 0;

let scrollInterval = null;

const didStopMoving = () => {
  if (!scrollInterval) return;
  clearInterval(scrollInterval);
  scrollInterval = null;
  console.log("ENDED");
  stopSFXForScroll();
};

let stopMovingTimeout = null;
const motionIntervalLength = (25 * 0.5) | 0;
const moveEndTimeout =
  50 >= motionIntervalLength ? motionIntervalLength * 0.8 : 50;

const updateMotion = () => {
  onDelta(Math.floor(pixelPos));
  const EPS = 1.0;
  const delta = Math.abs(pixelPos - prevPixelPos);
  if (delta <= EPS) {
    console.log("DELTA = ", delta);
    let curr = pixelPos;
    if (stopMovingTimeout) clearTimeout(stopMovingTimeout);
    stopMovingTimeout = setTimeout(() => {
      stopMovingTimeout = null;
      if (Math.abs(pixelPos - curr) <= EPS) didStopMoving();
    }, moveEndTimeout);
  }
  prevPixelPos = pixelPos;
};

const didMove = () => {
  if (scrollInterval) return;
  console.log("X STARTED");
  startSFXForScroll();
  updateMotion();
  scrollInterval = setInterval(updateMotion, motionIntervalLength);
};

class DrawerContainer extends Component {
  constructor(props, b) {
    super(props, b);

    const { items } = props;

    const searchParams = new URLSearchParams(props.location.search);

    if (searchParams.has("currTrack")) {
      pos = this._positionForItem(searchParams.get("currTrack"), items);
    } else if (searchParams.has("month") && searchParams.has("year")) {
      pos = this._positionForYearAndMonth(
        searchParams.get("year"),
        searchParams.get("month"),
        items
      );
    } else if (searchParams.has("position")) {
      let p = parseFloat(searchParams.get("position"));
      if (_.isFinite(p)) {
        pos = p;
        // console.log('got position:', pos)
      }
    }

    this.updateCurrDate(pos, items);

    this.state = {
      position: pos,
      width: measureWidth(),
      height: measureHeight(),
    };

    //called frequently during scrolling
    this.updateCurrDate = _.debounce(this.updateCurrDate.bind(this), 300, {
      trailing: true,
    });
    this.updatePositionUrl = _.debounce(
      this.updatePositionUrl.bind(this),
      300,
      { trailing: true }
    );

    this.updateScrollPosition = this.updateScrollPosition.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onScrub = this.onScrub.bind(this);
    this.onWindowFocus = this.onWindowFocus.bind(this);
    this.viewMore = this.viewMore.bind(this);
    this.clickYear = this.clickYear.bind(this);
    this.boundYear = this.boundYear.bind(this);
    this.startedScrubbing = this.startedScrubbing.bind(this);
    this.endedScrubbing = this.endedScrubbing.bind(this);

    this._didScroll = this._didScroll.bind(this);
    this._didScroll = _.throttle(this._didScroll, 100);
  }
  startedScrubbing() {
    console.log("started scrubbing!");
    setMaxSpeed(0);
  }
  endedScrubbing() {
    console.log("ended scrubbing!");
    setMaxSpeed(50);
  }
  shouldComponentUpdate(newprops, newstate) {
    return true; // TODO
    return (
      this.state.position !== newstate.position ||
      this.state.width !== newstate.width ||
      this.state.height !== newstate.height
    );
  }
  updateScrollPosition(pct) {
    if (pct === this.state.position) return;

    /* can happen when there are not enough items
     * to fill the viewport at initial render */
    if (_.isNaN(pct)) return;
    pos = pct;
    pixelPos = this._getPixelPosition(pos);
    onDelta(pixelPos);

    this._didScroll(pct);
    /* didMove()*/
  }
  _didScroll(pct) {
    this.updateCurrDate(pct, this.props.items);
    this.setState({ position: pct }, this.updatePositionUrl);
  }
  updateCurrDate(pos, items) {
    setCurrentTrack(this._getCurrentlyCenteredItem(pos, items));
  }
  updatePositionUrl() {
    let { position } = this.state;
    // Actualizar para usar history en lugar de router.replace
    this.props.history.replace(`/drawer?position=${position}`);
  }
  onResize() {
    let width = measureWidth();
    let height = measureHeight();
    this.setState({ width, height });
  }
  onWindowFocus() {
    _.delay(this.onResize, 30);
  }
  componentDidMount() {
    window.addEventListener("resize", this.onResize, false);
    window.addEventListener("focus", this.onWindowFocus, false);
    this.onResize();
    this.onScrub(this.state.position);
  }
  componentWillMount() {
    resetMetadata();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize, false);
    window.removeEventListener("focus", this.onWindowFocus, false);
    didStopMoving();
  }
  viewMore(item) {
    // Cambiar a usar this.props.history
    let { id, isAlbum, isFilm, isPlaylist, playlistId } = item;
    if (id === "playlists-section") return;
    if (isAlbum) {
      this.props.history.push(`/album?id=${id}`);
    } else if (isPlaylist) {
      this.props.history.push(`/playlist?id=${id || playlistId}`);
    } else if (isFilm) {
      this.props.history.push(`/film?id=${id}`);
    } else {
      this.props.history.push(`/info-card?track=${id}`);
    }
  }
  onScrub(amt) {
    this.refs.drawer.setScroll(amt);
    this.updateScrollPosition(amt);
  }
  clickYear(year, done) {
    let position = this._positionForYear(year);
    this.setState(
      {
        animating: true,
        targetYear: year,
      },
      () => {
        this.animateTo(position, () => {
          this.setState({ animating: false, targetYear: null });
          if (done) done();
        });
      }
    );
  }
  animateTo(newPosition, done) {
    let { position } = this.state;
    let delta = newPosition - position;
    let duration = 1000;
    let fps = 30;
    let frame = 1000 / fps;
    let frameCount = duration / frame;
    let step = 1.0 / frameCount;

    let current = 0;
    let currentFrame = 0;

    let interval = setInterval(() => {
      currentFrame++;
      current += step;
      let pos = Easing.easeInOutQuad(current) * delta + position;
      this.onScrub(pos);
      if (currentFrame >= frameCount) {
        clearInterval(interval);
        this.onScrub(newPosition);
        if (done) done();
      }
    }, frame);
  }
  _positionForItemIndex(items, i, center = true) {
    //maximum position depends on page height,
    //as the index at 100% is one page's worth
    //from the end of the collection
    let screensWorth = measureHeight() / itemHeight;
    let itemsize = (1 / (items.length - screensWorth)) * 100;
    let pos = itemsize * i;

    if (center) {
      //need to center the item in the full window, not just the content area
      let fullBottomHalf = measureFullHeight() / 2;
      let topHalfScreen = (measureHeight() - fullBottomHalf) / itemHeight;

      //center by half the page height (adjusting for content area)
      pos -= topHalfScreen * itemsize;
      //center item
      pos += itemsize / 2;
    }

    return Math.max(0, Math.min(100, pos));
  }
  /* returns centered position */
  _positionForItem(id, items = this.props.items) {
    for (let i = 0; i < items.length; i++) {
      if (items[i] && items[i].id === id) {
        return this._positionForItemIndex(items, i);
      }
    }
    return 100;
  }
  /* returns centered position */
  _positionForYearAndMonth(year, month, items = this.props.items) {
    let result = (idx) => {
      //uncentered, as date is displayed for item at top of viewport
      return this._positionForItemIndex(items, idx, false);
    };

    let hasSeenDate = false;
    let date = { year, month: monthNumber(month), day: 1 };

    for (let i = 0; i < items.length; i++) {
      let item = items[i];

      if (item.completionDate) {
        let other = item.completionDate;
        let cmp = compareReleaseDate(date, other);
        if (cmp === -1) {
          if (hasSeenDate) return result(i);
          else return 0;
        } else if (cmp === 0) {
          return result(i);
        }
      }

      if (item.completionDate) hasSeenDate = true;
    }

    return 100;
  }
  /* returns position at top of viewport */
  _positionForYear(y) {
    let { items } = this.props;

    let screensWorth = measureHeight() / itemHeight;

    let pct = (idx) => (idx / (items.length - 1 - screensWorth)) * 100;

    let hasSeenDate = false;
    for (let i = 0; i < items.length; i++) {
      let item = items[i];

      if (item.completionDate) {
        let { year } = item.completionDate;
        year -= 1;
        if (year > y) {
          if (hasSeenDate) return pct(i);
          else return 0;
        } else if (year === y) {
          return pct(i);
        }
      }

      if (item.completionDate) hasSeenDate = true;
    }
    return 100;
  }
  boundYear(year) {
    return Math.max(this._getMinYear(), Math.min(year, this._getMaxYear()));
  }
  _getMinYear() {
    let { items } = this.props;
    for (let i = 0; i < items.length; i++) {
      if (items[i].completionDate) return items[i].completionDate.year;
    }
  }
  _getMaxYear() {
    let { items } = this.props;
    for (let i = items.length - 1; i >= 0; i--) {
      if (items[i].completionDate) return items[i].completionDate.year;
    }
  }
  /* returns the date of the item at the top of the viewport */
  _getCurrentDate(position = this.state.position, items = this.props.items) {
    //maximum position depends on page height,
    //as the index at 100% is one page's worth
    //from the end of the collection
    let screensWorth = measureHeight() / itemHeight;

    //adjust a little so that items flush at the top of the screen register
    screensWorth -= 0.5;

    let idx = Math.floor((position / 100) * (items.length - screensWorth));

    idx = Math.min(items.length - 1, Math.max(0, idx));

    let item = items[idx];
    let dir = idx < items.length / 2 ? +1 : -1;
    while ((!item || !item.completionDate) && idx >= 0 && idx < items.length) {
      //move in from the edges since the padding items don't have completionDates
      idx += dir;
      item = items[idx];
    }
    return item.completionDate;
  }
  /* returns the pixel position of the item at the top of the viewport */
  _getPixelPosition(position = this.state.position, items = this.props.items) {
    //maximum position depends on page height,
    //as the index at 100% is one page's worth
    //from the end of the collection
    let screensWorth = this.state.height / itemHeight;
    let count = items.length - screensWorth;

    return count * (position / 100) * itemHeight;
  }
  _getCurrentlyCenteredItem(
    position = this.state.position,
    items = this.props.items
  ) {
    //maximum position depends on page height,
    //as the index at 100% is one page's worth
    //from the end of the collection
    let screensWorth = measureHeight() / itemHeight;

    let idx = (position / 100) * (items.length - screensWorth);

    // center index in viewport
    idx = Math.floor(idx + screensWorth / 2) - 1;
    // clamp to bounds
    idx = Math.min(items.length - 1, Math.max(0, idx));

    let item = items[idx];
    let dir = idx < items.length / 2 ? +1 : -1;

    while ((!item || !item.completionDate) && idx >= 0 && idx < items.length) {
      //move in from the edges since the padding items don't have completionDates
      idx += dir;
      item = items[idx];
    }
    return item;
  }
  render() {
    let pct = this.state.position;
    let { animating, targetYear, componentWasUpdatedInRender } = this.state;
    let year = animating ? targetYear : this._getCurrentDate().year;

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.updateItems &&
      !componentWasUpdatedInRender
    ) {
      this.setState({ componentWasUpdatedInRender: true });
    }

    return (
      <div id="drawer">
        <TopBar />
        <Drawer
          ref="drawer"
          onScroll={this.updateScrollPosition}
          pct={pct}
          viewMore={this.viewMore}
          measureWidth={measureWidth}
          measureHeight={measureHeight}
          items={this.props.items}
          dispatch={this.props.dispatch}
        />
        <div className="shader-wrapper top">
          <div className="shader top"></div>
        </div>
        <VerticalCenter className="scrubber-container">
          <DrawerScrubber
            onScroll={this.onScrub}
            pct={pct}
            moveStart={this.startedScrubbing}
            moveEnd={this.endedScrubbing}
          />
        </VerticalCenter>
        <VerticalCenter className="years-container">
          <YearsDisplay
            year={year}
            boundYear={this.boundYear}
            onClick={this.clickYear}
          />
        </VerticalCenter>
        <GlobalMenuIcon />
        <GlobalMapIcon />
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  if (state.status !== "drawerLoaded") {
    return {
      loaded: false,
    };
  }
  const processedState = processState(state);
  const { items } = processedState;

  return { loaded: true, tracks: state.tracks, items };
};

const DrawerWrapper = (props) => {
  if (!props.loaded)
    return (
      <div id="drawer">
        <TopBar />
        <SplashScreen loadState={100} />
      </div>
    );
  return <DrawerContainer {...props} />;
};

export default withRouter(connect(mapStateToProps)(DrawerWrapper));
